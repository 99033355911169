import React, { Component } from 'react'
import * as THREE from 'three'

import Stats from 'three/examples/jsm/libs/stats.module.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

class Scene extends Component {
    constructor(props) {
        super(props)

        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.animate = this.animate.bind(this)
        this.loadGLTF = this.loadGLTF.bind(this)
    }

    componentDidMount() {
        const width = this.mount.clientWidth
        const height = this.mount.clientHeight

        const scene = new THREE.Scene()
        const camera = new THREE.PerspectiveCamera(
            75,
            width / height,
            0.1,
            1000
        )
        const renderer = new THREE.WebGLRenderer({ antialias: true })
        const geometry = new THREE.BoxGeometry(1, 1, 1)
        const material = new THREE.MeshBasicMaterial({ color: '#ff6cb9' })
        // const cube = new THREE.Mesh(geometry, material)
        //
        // cube.rotation.x = 0.4;
        camera.position.z = 4
        // scene.add(cube)
        renderer.setClearColor('#ff6cb9')
        renderer.setSize(width, height)

        let dirLight, stats;
        // let mixer, controls;

        this.clock = new THREE.Clock();
        let container = this.mount;

        // stats = new Stats();
        // container.appendChild( stats.dom );

        // let renderer = new THREE.WebGLRenderer( { antialias: true } );
        renderer.setPixelRatio( window.devicePixelRatio );
        // renderer.setSize( window.innerWidth, window.innerHeight );
        renderer.outputEncoding = THREE.sRGBEncoding;
        container.appendChild( renderer.domElement );
        
        scene.background = new THREE.Color( 0xff6cb9 );

        // camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 100 );
        camera.position.set( 5, 2, 4.5 );

        this.controls = new OrbitControls( camera, renderer.domElement );
        this.controls.target.set( 0, 0.5, 0 );
        this.controls.update();
        this.controls.enablePan = false;
        this.controls.enableDamping = true;

        scene.add( new THREE.HemisphereLight( 0xffffff, 0x000000, 0.4 ) );

        dirLight = new THREE.DirectionalLight( 0xffffff, 1 );
        dirLight.position.set( 5, 2, 8 );
        scene.add( dirLight );

        // envmap
        let dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath( 'https://threejs.org/examples/js/libs/draco/gltf/' );

        let loader = new GLTFLoader();
        loader.setDRACOLoader( dracoLoader );
        loader.load( "https://threejs.org/examples/models/gltf/LittlestTokyo.glb", (gltf => this.loadGLTF(gltf)), (progress) => {
            // console.log(progress)
        }, function ( e ) {
            console.error( e );
        } );

        this.scene = scene
        this.camera = camera
        this.renderer = renderer
        this.material = material
        // this.cube = cube

        this.mount.appendChild(this.renderer.domElement)
        this.start()
    }

    loadGLTF ( gltf ) {
        let path = 'textures/cube/Park2/';
        let format = '.jpg';
        let envMap = new THREE.CubeTextureLoader().load( [
            path + 'posx' + format, path + 'negx' + format,
            path + 'posy' + format, path + 'negy' + format,
            path + 'posz' + format, path + 'negz' + format
        ] );


        let model = gltf.scene;
        model.position.set( 1, 1, 0 );
        model.scale.set( 0.01, 0.01, 0.01 );
        model.traverse( function ( child ) {

            if ( child.isMesh ) child.material.envMap = envMap;

        } );

        this.scene.add( model );

        this.mixer = new THREE.AnimationMixer( model );
        this.mixer.clipAction( gltf.animations[ 0 ] ).play();

        this.animate();

    }

    componentWillUnmount() {
        this.stop()
        this.mount.removeChild(this.renderer.domElement)
    }

    start() {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    }

    stop() {
        cancelAnimationFrame(this.frameId)
    }

    animate() {
        // this.cube.rotation.y += 0.01
        // // this.cube.rotation.z += 0.01

        window.requestAnimationFrame( this.animate );

        let delta = this.clock.getDelta();

        if(this.mixer) {
            this.mixer.update(delta);
        }

        this.controls.update();

        // stats.update();

        this.renderer.render( this.scene, this.camera );

        // this.renderScene()
        // this.frameId = window.requestAnimationFrame(this.animate)
    }

    renderScene() {
        this.renderer.render(this.scene, this.camera)
    }

    render() {
        return (
            <div
                style={{ width: '410px', height: '310px' }}
                ref={(mount) => { this.mount = mount }}
            />
        )
    }
}

export default Scene;
import {Col, Container, Row} from "react-bootstrap";
import {motion} from "framer-motion";
import React from "react";
import SwipeComponent from "./SwipeComponent";
import AFrameComponent from "./AFrameComponent";
import Scene from './Scene';
import Menu from "./Menu";

const MainScreen = (props) => {
    return (
        <Container>
            <Row>
                <Col>
                    <motion.div
                        animate={{
                            scale: [0.75, 1.0, 1.0, 0.75, 0.75],
                            rotate: [0, 0, 270, 270, 0],
                            borderRadius: ["20%", "20%", "50%", "50%", "20%"]
                        }}
                        transition={{
                            duration: 2,
                            ease: "easeInOut",
                            times: [0, 0.2, 0.5, 0.8, 1],
                            loop: Infinity,
                            repeatDelay: 1
                        }}
                    />
                </Col>
                <Col>
                    <div className="swipe-container">
                        <SwipeComponent />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="scene">
                        <Scene />
                    </div>
                </Col>
                <Col>
                    <div>
                        <Menu />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default MainScreen;
import React, {useState} from 'react';
import './App.css';
import { motion, useMotionValue, useTransform } from "framer-motion";
import MainScreen from "./components/MainScreen";

const icon = {
    hidden: {
        opacity: 0,
        pathLength: 0,
        fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
        opacity: 1,
        pathLength: 1,
        fill: "rgba(255, 255, 255, 1)",
        transition: { duration: 1.5 }
    }
};

const variants = {
    open: {
        opacity: 1
    },
    closed: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

function App() {
    const [loading, setLoading] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);

        return (
            <React.Fragment>
                {
                ( loading ?
                    <motion.div
                        className='loading-container'
                        animate={fadeOut ? "closed" : "open"}
                        variants={variants}
                        onAnimationComplete={() => {
                            if(fadeOut) {
                                setLoading(false);
                            }
                        }}
                    >
                        <div>
                            <motion.svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 50 50"
                                className="item"
                            >
                                <motion.path
                                    d=" M10,10
                                        A45,45 0 0,0
                                        40,40"
                                    variants={icon}
                                    fill="none"
                                    strokeWidth="2"
                                    stroke='#660000'
                                    initial="hidden"
                                    animate="visible"
                                    onAnimationComplete={() => {
                                        setFadeOut(true);
                                    }}
                                />
                                <motion.path
                                    d=" M20,10
                                        A30,30 0 0,0
                                        40,30"
                                    variants={icon}
                                    fill="none"
                                    strokeWidth="2"
                                    stroke='#660000'
                                    initial="hidden"
                                    animate="visible"
                                />
                                <motion.path
                                    d=" M30,10
                                        A20,20 0 0,0
                                        40,20"
                                    variants={icon}
                                    fill="none"
                                    strokeWidth="2"
                                    stroke='#660000'
                                    initial="hidden"
                                    animate="visible"
                                />
                            </motion.svg>
                        </div>
                    </motion.div> : '' )
                }
                <MainScreen />
            </React.Fragment>
        );
}

export default App;

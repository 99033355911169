import React, {useRef} from 'react';
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions.js";
import { MenuToggle } from "./MenuToggle";
import {Navigation} from "./Navigation";

const sidebar = {
    open: {
        width: '100%'
    },
    closed: {
        width: '0%'
    }
};

const Menu = (props) => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);

    return (
        <motion.nav
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            ref={containerRef}
        >
            <motion.div className="background" variants={sidebar} />
            <MenuToggle toggle={() => toggleOpen()} />
            <Navigation />
        </motion.nav>
    );
}

export default Menu;